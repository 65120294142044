@import "@aws-amplify/ui-react/styles.css";
:root {
  --main-color: #8F7247;
  --background-color: #E2E8F3;
  --background-gray: #F6F4F2;
  --background-white: #FFFFFF;
  --information-gray: #EBEBEB;
  --primary-button-background: #000000;
  --main-text-color: #000000;
  --secondary-text-color: ##666666;
  --banner-text-color: #FFFFFF;
  --separator-gray: #A1A1A1;
  --success-green: #3D8705;
  --warning-yellow: #FAAF19;
  --danger-red: #DD3232;
  --statistics-success: #3F9C35;
  --statistics-warning: #FA6119;
  --statistics-danger: #D50000;
  
  --amplify-primary-color: var(--primary-button-background);
  --amplify-primary-shade: var(--primary-button-background);
  --amplify-primary-tint: var(--background-color);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

amplify-container {
  background-color: var(--background-color);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: var(--background-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
